import axios from 'axios'

const apiClient = axios.create({
    baseURL: "https://jsonplaceholder.typicode.com/todos/1"
})

export default {
    getEvents() {
        return apiClient.get()
    }
}

// events
// https://apps.analytichealth.co.uk/REST_API/prescribing-england?active_ingredients=buprenorphine

// ,
//     withCredentials: false,
//         headers: {
//             accept: '*/*',
//             'Content-Type': 'application/json',
//             Authorization: ``
//         }