<template>
  <div class="data">
    <img alt="Analytic-Health-Logo" src="../assets/ah_logo.png" style="height:50px;"/>
    <HelloWorld msg="Welcome to Pharmly Cloud Data" />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from "@/components/HelloWorld.vue";
import EventService from "@/services/EventService.js";

export default {
  name: "Data",
  components: {
    HelloWorld
},
created() {
  EventService.getEvents()
  .then(response => {
    this.events = response.data
  })
  .catch(error => {
    console.log(error)
  })
}
};
</script>

